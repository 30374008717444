import { useT } from "react-polyglot-hooks"
import { NextSeo } from "next-seo"
import DefaultLayout from "components/layouts/DefaultLayout"
import { Container, FullContainer } from "components/styled/Grid"
import Typography from "components/styled/Typography"
import styled from "styled-components"
import Link from "components/Link"
import { PrimaryButton } from "components/styled/Buttons/PrimaryButton"
import LazyLoad from "react-lazyload"
import Kinds from "components/Kinds"

const Image = styled.img`
  margin: 50px auto;
  display: block;
`

export default function NotFoundScene({ noIndex = true }) {
  const t = useT()

  return (
    <DefaultLayout page="not_found">
      <NextSeo noindex={noIndex} title={t("not_found.title")} />
      <Container top={50} bottom={25}>
        <Typography variant="title2" weight="medium" center space>
          {t("not_found.title")}
        </Typography>
        <Typography center space>
          {t("not_found.subtitle")}
        </Typography>
        <Image alt="Closed road" src={`${process.env.NEXT_PUBLIC_CLOUDINARY}w_auto,q_auto/static/closed-road.svg`} />
        <Link page="home">
          <PrimaryButton as="a" size="xxl">
            {t("not_found.button")}
          </PrimaryButton>
        </Link>
      </Container>
      <FullContainer maxWidth={1024}>
        <LazyLoad height={517}>
          <Kinds page="not_found" />
        </LazyLoad>
      </FullContainer>
    </DefaultLayout>
  )
}
